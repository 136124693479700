import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { CmsStore } from 'theme/stores/cms/cms';
import { InnerHTML } from '@zento/modules/atom/InnerHTML';

import { ICmsBlock } from './CmsBlock.d';
import style from './style.scss';

/**
 * CmsBlock
 *
 * Renders html content from Magento admin cms blocks
 */
@Component({})
export class CmsBlock extends BaseComponent<ICmsBlock, unknown> {
  protected static T = {
    readMore: 'read_more',
    readLess: 'read_less',
  };

  /**
   * Block identifier
   */
  @Prop({ type: String, required: true })
  identifier: string;

  /**
   * Block content
   */
  @Prop({ type: String, required: false, default: '' })
  content?: string;

  /**
   * Determines show more content
   */
  @Prop({ type: Boolean, required: false, default: false })
  showMoreContent?: boolean;

  /**
   * Widget row heading
   */
  @Prop({ type: String, required: false, default: '' })
  heading?: string;

  /**
   * Widget row heading position
   */
  @Prop({ type: String, required: false, default: '' })
  headingPosition?: string;

  private cmsStore = new CmsStore();

  data() {
    return {
      showContent: false,
    };
  }

  async mounted() {
    return await this.fetchBlocksData();
  }

  async fetchBlocksData() {
    if (this.identifier && (!this.content || !this.cmsData)) {
      return this.cmsStore.fetchCmsBlocksData([this.identifier]);
    }
  }

  async serverPrefetch() {
    return await this.fetchBlocksData();
  }

  private get cmsData() {
    return this.cmsStore.getBlockByIdentifier(this.identifier);
  }

  /**
   * Truncate checkbox label
   */
  private get truncateContent() {
    const config = this.extended.$config.zento.theme;
    const content = this.content || this.cmsData?.content;

    if (content.length <= config.checkboxCustomLabelLength) {
      return content;
    }

    return content.slice(0, config.checkboxCustomLabelLength) + '...';
  }

  /**
   * Determines if show more button is needed
   */
  private get showMore() {
    const config = this.extended.$config.zento.theme;
    const content = this.content || this.cmsData?.content;

    if (content.length <= config.checkboxCustomLabelLength && !this.showMoreContent) {
      return false;
    }

    return true;
  }

  /**
   * Show full content
   */
  showMoreDescription() {
    this.$data.showContent = !this.$data.showContent;
  }

  render() {
    const content = this.content || this.cmsData?.content;

    return content ? (
      <span>
        {this.heading ? (
          <h2
            class={{
              [style.centerTitle]: this.headingPosition === 'center',
              [style.rightTitle]: this.headingPosition === 'right',
            }}>
            {this.heading}
          </h2>
        ) : null}

        <InnerHTML
          contents={!this.$data.showContent && this.showMoreContent ? this.truncateContent : content}
          class={[style.cmsBlock, style.container]}
        />

        {this.showMore && this.showMoreContent ? (
          <span class={style.showMore} onClick={this.showMoreDescription}>
            {this.$data.showContent ? (
              <span>{this.getTranslation({ id: CmsBlock.T.readLess })}</span>
            ) : (
              <span>{this.getTranslation({ id: CmsBlock.T.readMore })}</span>
            )}
          </span>
        ) : null}
      </span>
    ) : null;
  }
}
