import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import type { ExtractArrayType } from '@zento-lib/util/types';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import { fetchCmsBlocks, FetchCmsBlocksQuery, fetchCmsPage, FetchCmsPageQuery } from './operations.graphql';
import type { Row } from './types';

export type CmsPageData = Omit<FetchCmsPageQuery['builderPage'], 'content'> & { content: Row };
type CmsBlock = ExtractArrayType<FetchCmsBlocksQuery['cmsBlocks']['items']>;

@Store
export class CmsStore extends BaseStore {
  /**
   * CMS Blocks keeper
   */
  @Field
  private cmsBlocks: CmsBlock[];

  /**
   * CMS Page keeper
   */
  @Field
  private cmsPage: Record<string, CmsPageData>;

  /**
   * Fetch cms blocks data by identifiers
   */
  public async fetchCmsBlocksData(identifiers: string[]) {
    let blocks: FetchCmsBlocksQuery = { cmsBlocks: { items: [] } };

    try {
      blocks = await this.dataSources.graphQl.queue({
        ...fetchCmsBlocks,
        params: { identifiers, storeId: currentStoreView().storeId },
      });
    } catch (e) {
      console.error('CMS Store (fetchCmsBlocksData): ', e);
    }

    this.cmsBlocks = [...(this.cmsBlocks || []), ...blocks.cmsBlocks.items];

    return this.cmsBlocks;
  }

  /**
   * Fetch cms page data by identifier
   */
  public async fetchCmsPageData(identifier: string) {
    let page: FetchCmsPageQuery = { builderPage: {} };

    try {
      page = await this.dataSources.graphQl.queue({
        ...fetchCmsPage,
        params: { storeId: currentStoreView().storeId, identifier },
      });
    } catch (e) {
      console.error('CMS Store (fetchCmsPageData): ', e);
    }

    if (Object.keys(page?.builderPage).length) {
      this.cmsPage = {
        ...(this.cmsPage || {}),
        [identifier]: { ...page.builderPage, content: JSON.parse(page.builderPage.content || '{}')?.sections as Row },
      };
    }

    return this.cmsPage;
  }

  /**
   * Retrieve specific cms block data by it's identifier
   */
  public getBlockByIdentifier(identifier: string) {
    return this.cmsBlocksMap[identifier] ?? null;
  }

  /**
   * Compute a map of cms blocks identifier to cms blocks values in order to allow fast selection
   */
  private get cmsBlocksMap() {
    return (this.cmsBlocks || []).reduce((ret, i) => ({ ...ret, [i.identifier]: i }), {} as Record<string, CmsBlock>);
  }

  /**
   * Retrieve specific cms page data by it's identifier
   */
  public getPageByIdentifier(identifier: string) {
    return (this.cmsPage || {})[identifier] ?? null;
  }
}
